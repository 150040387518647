import { createApp } from "vue";
import router from "./router";
import App from "./App.vue";
import store from "./store";
import BootstrapVue3 from "bootstrap-vue-3";
import VueSmoothScroll from 'vue3-smooth-scroll'
import { createHead } from '@unhead/vue'
import { register } from 'swiper/element/bundle';
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger.js";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue-3/dist/bootstrap-vue-3.css";
import '@fortawesome/fontawesome-free/css/all.css'
import '@fortawesome/fontawesome-free/js/all.js'
import './assets/sass/style.scss';

gsap.registerPlugin(ScrollTrigger);
const head = createHead()
// register Swiper custom elements
register();

const app = createApp(App);

const lang = document.documentElement.lang;
app.config.globalProperties.$langView = lang === 'en' ? 'viewEN' : 'viewZH';

app.use(store)
   .use(router)
   .use(gsap)
   .use(BootstrapVue3)
   .use(head)
   .use(VueSmoothScroll)
   .mount("#app");
