import { createRouter, createWebHashHistory } from "vue-router";
// createWebHistory 
// import blog from "@/components/blog/blog.json";

const routes = [
  {
    path: "/",
    name: "home",
    component: () => import("../views/home/DemoThree.vue"),
  },
  {
    path: "/en-US",
    name: "home-en",
    component: () => import("../views/home/DemoThree-en.vue"),
  },
  // {
  // 	path: "/main-home",
  // 	name: "Main Home",
  // 	component: () => import("../views/home/MainDemo.vue"),
  // },
  // {
  // 	path: "/demo-2",
  // 	name: "Demo 2",
  // 	component: () => import("../views/home/DemoTwo.vue"),
  // },
  // {
  // 	path: "/demo-3",
  // 	name: "Demo 3",
  // 	component: () => import("../views/home/DemoThree.vue"),
  // },
  // {
  // 	path: "/about",
  // 	name: "about",
  // 	component: () => import("../views/AboutUs.vue"),
  // },
  // {
  // 	path: "/grid-2-columns",
  // 	name: "grid-2-columns",
  // 	component: () => import("../views/portfolio/grids/GridTwo.vue"),
  // },
  // {
  // 	path: "/grid-3-columns",
  // 	name: "grid-3-columns",
  // 	component: () => import("../views/portfolio/grids/GridThree.vue"),
  // },
  // {
  // 	path: "/what-if-gold",
  // 	name: "what-if-gold",
  // 	component: () => import("../views/portfolio/projects/WhatIfGold.vue"),
  // },
  // {
  // 	path: "/bastian-bux",
  // 	name: "bastian-bux",
  // 	component: () => import("../views/portfolio/projects/BastianBux.vue"),
  // },
  // {
  // 	path: "/re-styling",
  // 	name: "re-styling",
  // 	component: () => import("../views/portfolio/projects/ReStyling.vue"),
  // },
  // {
  // 	path: "/under-armour",
  // 	name: "under-armour",
  // 	component: () => import("../views/portfolio/projects/UnderArmour.vue"),
  // },
  // {
  // 	path: "/novara-conic",
  // 	name: "novara-conic",
  // 	component: () => import("../views/portfolio/projects/NovaraConic.vue"),
  // },
  // {
  // 	path: "/sleep-walker",
  // 	name: "sleep-walker",
  // 	component: () => import("../views/portfolio/projects/SleepWalker.vue"),
  // },
  // {
  // 	path: "/nile-kabutha",
  // 	name: "nile-kabutha",
  // 	component: () => import("../views/portfolio/projects/NileKabutha.vue"),
  // },
  // {
  // 	path: "/toast-reel",
  // 	name: "toast-reel",
  // 	component: () => import("../views/portfolio/projects/ToastReel.vue"),
  // },
  // {
  // 	path: "/time-tag-watch",
  // 	name: "time-tag-watch",
  // 	component: () => import("../views/portfolio/projects/TimeTagWatch.vue"),
  // },
  // {
  // 	path: "/blog/:page",
  // 	name: "blog",
  // 	component: () => import("@/views/blog/BlogList.vue"),
  // },
  // {
  // 	path: "/article/:id/:slug",
  // 	name: "article",
  // 	query: { xxx: 1 },
  // 	component: () => import("../views/blog/BlogDetails.vue"),
  // 	beforeEnter(to, from, next) {
  // 		const id = parseInt(to.params.id);
  // 		const LocalArticles = blog;
  // 		const exists = LocalArticles.some((article) => article.id === id);
  // 		if (exists) {
  // 			next((vm) =>
  // 				vm.getData({
  // 					path: to.query.page,
  // 					replace: true,
  // 				})
  // 			);
  // 		} else {
  // 			next({
  // 				path: "not-found",
  // 				replace: true,
  // 			});
  // 		}
  // 	},
  // },
  // {
  // 	path: "/slider",
  // 	name: "slider",
  // 	component: () => import("../views/portfolio/slider/SliderFullScreen.vue"),
  // },
  // {
  // 	path: "/slider-2",
  // 	name: "slider-2",
  // 	component: () => import("../views/portfolio/slider/SliderPadding.vue"),
  // },
  // {
  // 	path: "/slider-3",
  // 	name: "slider-3",
  // 	component: () => import("../views/portfolio/slider/SliderHalf.vue"),
  // },
  // {
  // 	path: "/contact",
  // 	name: "contact",
  // 	component: () => import("../views/ContactUs.vue"),
  // },
  // {
  // 	path: "/:pathMatch(.*)*",
  // 	name: "not-found",
  // 	component: () => import("../views/CompError.vue"),
  // },
];

const router = createRouter({
  // history: createWebHistory(process.env.BASE_URL),
//   history: createWebHistory(`./`),
  history: createWebHashHistory('/'), // 使用 hash 模式
//   base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
	const lang = navigator.language || navigator.userLanguage;
  
	// 判斷瀏覽器語言並重定向
	if (lang.startsWith("en") && to.path !== "/en-US") {
	  next({ path: "/en-US" }); // 英文路徑
	} else if (lang.startsWith("zh") && to.path === "/en-US") {
	  next({ path: "/" }); // 繁體中文
	} else {
	  next(); //
	}
});

export default router;
