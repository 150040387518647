<template>
  <div id="box" ref="preloader" class="preloader">
    <div ref="preloader_after" class="preloader-after"></div>
    <div ref="preloader_before" class="preloader-before"></div>
    <div ref="preloader_block" class="preloader-block">
      <div ref="progress_title" class="title">NeuroBrain</div>
      <div ref="progress_number" class="percent">0</div>
      <div ref="progress_loading" class="loading">loading...</div>
    </div>
    <div ref="preloader_bar" class="preloader-bar">
      <div ref="preloader_progress" class="preloader-progress"></div>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted, onUnmounted } from "vue";
import gsap from "gsap";
import TweenMax from "gsap/";
import "gsap/CSSPlugin";
gsap.registerPlugin(TweenMax);

const preloader = ref(null);
const preloader_block = ref(null);
const progress_number = ref(null);
const progress_title = ref(null);
const progress_loading = ref(null);
const preloader_bar = ref(null);
const preloader_progress = ref(null);
const preloader_after = ref(null);
const preloader_before = ref(null);
let animate = null;

onMounted(() => {
  animate = TweenMax.fromTo(
    preloader_progress.value,
    0.5,
    {
      width: "95%",
    },
    {
      width: "100%",
      onUpdate() {
        const f =
          (preloader_progress.value.clientWidth / preloader_block.value.clientWidth) *
          100;
        progress_number.value.innerText = parseInt(f, 10);
      },
      onComplete() {
        TweenMax.to(preloader_bar.value, 0.5, { left: "100%" });
        TweenMax.to(progress_title.value, 1, { autoAlpha: 0, y: -100 });
        TweenMax.to(progress_loading.value, 1, { autoAlpha: 0, y: 100 });
        TweenMax.to(progress_number.value, 1, { autoAlpha: 0 });
        TweenMax.to(preloader_before.value, 1, { y: "-100%", delay: 0.7 });
        TweenMax.to(preloader_after.value, 1, {
          y: "100%",
          delay: 0.7,
          onComplete() {
            preloader.value.classList.add("hidden");
          },
        });
      },
    }
  );
});

onUnmounted(() => {
  if (animate.scrollTrigger) animate.scrollTrigger.kill();

  animate.kill();
});
</script>

<style lang="scss" scoped>
.preloader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 99999;

  .preloader-before,
  .preloader-after {
    display: block;
    position: absolute;
    left: 0;
    width: 100%;
    height: 50%;
    background: #090808;
  }

  .preloader-before {
    top: 0;
  }

  .preloader-after {
    bottom: 0;
  }

  .preloader-block {
    position: absolute;
    top: 0;
    left: 0;
    right: auto;
    bottom: auto;
    width: 100%;
    height: 100%;

    .title {
      position: absolute;
      left: 0;
      bottom: calc(50% + 16px);
      width: 100%;
      text-align: center;
      z-index: 2;
      font-size: 25px;
      text-transform: uppercase;
      letter-spacing: 8px;
      color: #fff;
    }

    .percent {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate3d(-50%, -50%, 0);
      font-weight: 700;
      letter-spacing: 8px;
      font-size: 24vw;
      color: rgba(54, 54, 53, 0.1);
      z-index: 1;
    }

    .loading {
      position: absolute;
      top: calc(50% + 16px);
      left: 0;
      width: 100%;
      text-align: center;
      z-index: 2;
      font-size: 14px;
      font-weight: 400;
      text-transform: uppercase;
      letter-spacing: 8px;
      color: #fff;
      -webkit-animation: blink-animation 2.2s linear infinite;
      animation: blink-animation 2.2s linear infinite;
    }
  }

  .preloader-bar {
    position: absolute;
    z-index: 2;
    top: calc(50% - 1px);
    right: 0;
    width: 100%;
    height: 2px;
    background: #1d1d1d;

    .preloader-progress {
      position: absolute;
      top: 0;
      left: 0;
      right: auto;
      bottom: auto;
      height: 100%;
      width: 0;
      background: #fff;
    }
  }
}
</style>
